import React from "react";
import {
  contactModule,
  contactModule__content,
  contactModule__icon,
  compact,
  cta,
} from "../scss-modules/contact-us.module.scss";

export default function ContactModule({
  iconSrc,
  title,
  subText,
  buttonHref,
  buttonText,
  isCompact = true,
}) {
  return (
    <a
      className={`${contactModule} ${isCompact ? compact : ""} grid__column`}
      href={buttonHref}
      target="_blank"
      rel="noopener noreferrer"
    >
      <div className={contactModule__content}>
        <div>
          <img src={iconSrc} className={contactModule__icon} />
        </div>
        <div>
          <h4>{title}</h4>
          <p>{subText}</p>
          {!isCompact && (
            <div className={cta}>
              <span className="button primary arrow-light">
                <span>{buttonText}</span>
              </span>
            </div>
          )}
        </div>
      </div>
    </a>
  );
}
