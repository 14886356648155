import * as React from "react";
import { Link } from "gatsby";
import Layout from "../../components/layout/Layout";
import SplashBanner from "../../components/SplashBanner";
import {
  wrapper__row,
  contactIcon,
  socialIcon,
  gridThreeColumn,
  iconWithText,
  socialIconGroup,
} from "../../scss-modules/contact-us.module.scss";
import ContactModule from "../../components/ContactModule";

const bannerImg = "../../banners/contact-us_banner.jpg";

//RENDER
export default function ContactUsPage() {
  return (
    <Layout pageTitle={"Contact us"}>
      <SplashBanner
        title={"Contact Us"}
        content={"Contractor & Client 24/7 support."}
        backgroundImg={bannerImg}
      />

      <section>
        <div className="container">
          <div className="split-content">
            <div className="module text">
              <h3>Contact information</h3>
              <div className={wrapper__row}>
                {/* International */}
                <p>
                  <strong>International:</strong>
                </p>
                <div className={iconWithText}>
                  <img
                    src={"../../icons/font-awesome/phone.svg"}
                    className={contactIcon}
                  />
                  <p>
                    <a href="tel:+44 2077130569">+44 207 7130 569</a>{" "}
                    (International HQ)
                  </p>
                </div>
                <div className={iconWithText}>
                  <img
                    src={"../../icons/font-awesome/map-pin.svg"}
                    className={contactIcon}
                  />
                  <p> Performance Energy Ltd, United Kingdom</p>
                </div>

                {/* Saudi */}
                <p>
                  <strong>Saudi Arabia:</strong>
                </p>
                <div className={iconWithText}>
                  <img
                    src={"../../icons/font-awesome/phone.svg"}
                    className={contactIcon}
                  />
                  <p>
                    <a href="tel:+966 53 852 2206">+966 53 852 2206</a> (GCC HQ)
                  </p>
                </div>
                <div className={iconWithText}>
                  <img
                    src={"../../icons/font-awesome/map-pin.svg"}
                    className={contactIcon}
                  />
                  <p> Performance Energy Arabia Ltd, Saudi Arabia</p>
                </div>

                {/* Social icons */}
                <div className={socialIconGroup}>
                  <a
                    href="https://www.facebook.com/PerformanceEnergyltd"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={"../../icons/font-awesome/facebook.svg"}
                      className={`${contactIcon} ${socialIcon}`}
                    />
                  </a>
                  <a
                    href="https://www.linkedin.com/company/performance-energy/?viewAsMember=true"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={"../../icons/font-awesome/linkedin.svg"}
                      className={`${contactIcon} ${socialIcon}`}
                    />
                  </a>
                </div>
              </div>
            </div>
            <div className="module media small">
              <img
                src={"../../article/contact-us_article.jpg"}
                alt="Contact us today"
              />
            </div>
          </div>

          <div className={`${gridThreeColumn} grid`}>
            <ContactModule
              buttonHref="mailto:CVsubmit@performance-energy.com?subject=Submit my CV"
              buttonText="Send your CV"
              iconSrc="../../icons/font-awesome/register-profile.svg"
              title="Looking for work?"
              isCompact={false}
            />

            <ContactModule
              buttonHref="mailto:Personnel-Needs@performance-energy.com"
              buttonText="Email us"
              iconSrc="../../icons/font-awesome/personnel-requirement.svg"
              title="Personnel Needs"
              isCompact={false}
            />

            <ContactModule
              buttonHref="mailto:brokerage@performance-energy.com"
              buttonText="Email us"
              iconSrc="../../icons/font-awesome/equipment-required.svg"
              title="Equipment / Fuel requirements"
              isCompact={false}
            />
          </div>
        </div>
      </section>
    </Layout>
  );
}
